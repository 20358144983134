import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  mobileContentSidePadding,
} from '../../styles/index';
import paper from '../../../static/paperBackground/paper.jpeg';
import { useGlobalDictionaryQuery } from '../../hooks/index';
import RichText from '../shared/RichText';
import Button from '../shared/Button';
import LinkTool from '../shared/LinkTool';
import Freckles from '../shared/FrecklesOverlappable';

export interface OverlappedImagesProps {
  vidiosOverlapped: string;
  imagesOverlapped: {
    imagesOverlapText: {
      json: string;
    };
    imagesOverlapLink: {
      title: string;
      target: {
        slug: string;
      };
    };
    imagesOverlapMedia: {
      fluid: string;
    };
    imagesOverlapMediaDesktop: {
      fluid: string;
    };
    backgroundColorButtonInOverlapped: string;
    textColorButtonInOverlapped: string;
    borderColorButtonInOverlapped: string;
    backgroundColorButtonInOverlappedHover: string;
    textColorButtonInOverlappedHover: string;
    borderColorButtonInOverlappedHover: string;
    contentOverlapped: string;
  };
}

export default function HomeOverlappedImages(props: OverlappedImagesProps) {
  const {
    imagesOverlapped,
    vidiosOverlapped,
    backgroundColorButtonInOverlapped,
    textColorButtonInOverlapped,
    borderColorButtonInOverlapped,
    backgroundColorButtonInOverlappedHover,
    textColorButtonInOverlappedHover,
    borderColorButtonInOverlappedHover,
    contentOverlapped,
  } = props;

  const {
    imagesOverlapText,
    imagesOverlapMedia,
    imagesOverlapMediaDesktop,
    imagesOverlapLink,
  } = imagesOverlapped;

  const dictionary = useGlobalDictionaryQuery();

  // ------- Breakpoints business --------------------------------------

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) setIsMobile(false);
  }, []);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <>
        <FrecklesWrapper>
          <Freckles canvasHeight="100vh" />
        </FrecklesWrapper>
        <TextBlock>
          <RichText json={imagesOverlapText.json} />
          <ButtonWrapper>
            <LinkTool to={`/${imagesOverlapLink.target.slug}/`}>
              <Button
                text={dictionary?.explore}
                Type="button"
                AriaLabel="Send"
                width="100%"
                widthDesk={desktopVW(240)}
                color={textColorButtonInOverlapped}
                colorHover={textColorButtonInOverlappedHover}
                colorBackground={backgroundColorButtonInOverlapped}
                colorBackgroundHover={backgroundColorButtonInOverlappedHover}
                colorBorder={borderColorButtonInOverlapped}
                colorBorderHover={borderColorButtonInOverlappedHover}
              />
            </LinkTool>
          </ButtonWrapper>
        </TextBlock>
        <ImagesBlock>
          {contentOverlapped === true ? (
            <>
              <VideoPlayer controls={false} autoPlay loop playsInline muted preload="meta">
                <source src={vidiosOverlapped} type="video/mp4" />
              </VideoPlayer>
            </>
          ) : (
            <>
              <FrontImage>
                <FrontStyledImg
                  fluid={
                    isMobile ? imagesOverlapMedia[1].fluid : imagesOverlapMediaDesktop[1].fluid
                  }
                  loading="eager"
                />
              </FrontImage>
              <RearImage>
                <RearStyledImg
                  fluid={
                    isMobile ? imagesOverlapMedia[0].fluid : imagesOverlapMediaDesktop[0].fluid
                  }
                  loading="eager"
                />
              </RearImage>
            </>
          )}
        </ImagesBlock>
      </>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  height: auto;
  background: url(${paper});
  background-repeat: repeat;
  z-index: ${zIndex.backgrounds};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;

  ${desktopBreakpoint} {
    flex-direction: row;
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileVW(450)};
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint} {
    //position: absolute;
    height: 100%;
    width: 100%;
    left: auto;
    top: 0;
    right: 0;
  }
`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.dots};
  will-change: transform;
`;

const TextBlock = styled.div`
  height: auto;
  width: 100%;
  padding: ${mobileVW(50)} ${mobileContentSidePadding} ${mobileVW(20)} ${mobileContentSidePadding};
  z-index: ${zIndex.surface};

  h6 {
    font-family: 'messina';
    text-transform: uppercase;
    font-weight: 400;
    font-size: ${mobileVW(45)};
    line-height: 90%;
    color: ${colors.black};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(81)};
    }
  }

  ${desktopBreakpoint} {
    height: 100%;
    width: 50vw;
    padding: ${desktopVW(122)} 0 ${desktopVW(69)} ${desktopVW(61)};
  }
`;

const ButtonWrapper = styled.div`
  padding-top: ${mobileVW(20)};

  ${desktopBreakpoint} {
    padding-top: ${desktopVW(110)};
  }
`;

const ImagesBlock = styled.div`
  height: ${mobileVW(470)};
  width: 100%;
  position: relative;
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    height: 100%;
    width: 50vw;
  }
`;

const RearImage = styled.div`
  z-index: ${zIndex.surface - 1};
  position: absolute;
  top: ${mobileVW(20)};
  left: 0;
  width: ${mobileVW(300)};
  height: auto;
  will-change: transform;

  ${desktopBreakpoint} {
    top: ${desktopVW(66)};
    right: ${desktopVW(180)};
    left: auto;
    width: ${desktopVW(540)};
  }
`;

const FrontImage = styled.div`
  z-index: ${zIndex.surface};
  position: absolute;
  top: ${mobileVW(280)};
  right: ${mobileVW(20)};
  width: ${mobileVW(200)};
  height: auto;
  will-change: transform;

  ${desktopBreakpoint} {
    top: ${desktopVW(564)};
    right: ${desktopVW(60)};
    width: ${desktopVW(360)};
  }
`;

const FrontStyledImg = styled(Image)`
  width: ${mobileVW(200)};
  height: auto;
  ${desktopBreakpoint} {
    width: ${desktopVW(360)};
  }
`;

const RearStyledImg = styled(Image)`
  width: ${mobileVW(300)};
  height: auto;
  ${desktopBreakpoint} {
    width: ${desktopVW(540)};
  }
`;
