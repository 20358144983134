import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import {
  desktopBreakpoint,
  desktopVW,
  mobileVW,
  colors,
  zIndex,
  mobileContentSidePadding,
} from '../../styles/index';
import Button from '../shared/Button';
import { useGlobalDictionaryQuery, useLocale, useGlobalLayoutQuery } from '../../hooks/index';
import LinkTool from '../shared/LinkTool';

interface Props {
  backgroundImageMobile: {
    title: string;
    fluid: string;
  };
  backgroundImageDesktop: {
    title: string;
    fluid: string;
  };
  backgroundVidioDesktop: string;
  backgroundVidioMobile: string;
  heroCatchphrase: string;
  detailsColor: boolean;
  heroLink: {
    slug: string;
  };
  backgroundColorButtonInHeroSection: string;
  backgroundColorButtonInHeroSectionHover: string;
  textColorButtonInHeroSectionHover: string;
  textColorButtonInHeroSection: string;
  borderColorButtonInHeroSection: string;
  borderColorButtonInHeroSectionHover: string;
  backgroundHero: string;
}

export default function LandingPage(props: Props) {
  const {
    backgroundImageMobile,
    backgroundImageDesktop,
    backgroundVidioDesktop,
    backgroundVidioMobile,
    heroCatchphrase,
    detailsColor,
    heroLink,
    backgroundColorButtonInHeroSection,
    backgroundColorButtonInHeroSectionHover,
    textColorButtonInHeroSectionHover,
    textColorButtonInHeroSection,
    borderColorButtonInHeroSection,
    borderColorButtonInHeroSectionHover,
    backgroundHero,
  } = props;

  const dictionary = useGlobalDictionaryQuery();
  const layout = useGlobalLayoutQuery();

  // ------- Breakpoints business --------------------------------------

  const [isMobile, setIsMobile] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [ratioVidio, setRatioVidio] = useState(0);

  function getWindowDimensions() {
    if (typeof window !== 'undefined') {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    const calcRatio = windowDimensions?.width / windowDimensions?.height;
    setRatioVidio(calcRatio);
  }, [windowDimensions]);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) setIsMobile(false);
  }, []);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const locale = useLocale();

  return (
    <Wrapper>
      {backgroundHero === true ? (
        <VidioPlayerContainer>
          {isMobile ? (
            <VideoPlayerMobile
              style={{ width: ratioVidio * (windowDimensions?.height + 200) }}
              src={`https://www.youtube.com/embed/${backgroundVidioMobile}?autoplay=1&loop=1&rel=0&mute=1&controls=0&showinfo=0&color=white&playlist=${backgroundVidioMobile}&vq=hd1080`}
            />
          ) : (
            <VideoPlayer
              style={{ width: ratioVidio * windowDimensions?.width + 1000 }}
              src={`https://www.youtube.com/embed/${backgroundVidioDesktop}?autoplay=1&loop=1&rel=0&mute=1&controls=0&showinfo=0&color=white&playlist=${backgroundVidioDesktop}&vq=hd1080`}
            />
          )}
        </VidioPlayerContainer>
      ) : (
        <>
          <BackgroundImage
            fluid={isMobile ? backgroundImageMobile.fluid : backgroundImageDesktop.fluid}
            alt={backgroundImageMobile.title}
            loading="eager"
            fadeIn
          />
        </>
      )}

      <HeroFooter>
        <HeroCatchphrase detailsColor={detailsColor}>{heroCatchphrase}</HeroCatchphrase>
        <LinkTool to={`/${heroLink.target.slug}/`}>
          <Button
            text={dictionary?.explore}
            Type="button"
            AriaLabel="Send"
            width={mobileVW(350)}
            widthDesk={desktopVW(238)}
            color={textColorButtonInHeroSection}
            colorHover={textColorButtonInHeroSectionHover}
            colorBackground={backgroundColorButtonInHeroSection}
            colorBackgroundHover={backgroundColorButtonInHeroSectionHover}
            colorBorder={borderColorButtonInHeroSection}
            colorBorderHover={borderColorButtonInHeroSectionHover}
          />
        </LinkTool>
      </HeroFooter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 95vh;

  ${desktopBreakpoint} {
    height: 100vh;
  }
`;
const VidioPlayerContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh; /* whatever you want */
  pointer-events: none;
  width: 100vw; /
`;
const VideoPlayer = styled.iframe`
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 50%;
  height: 120vh;
  transform: translate(-50%, -50%);
`;

const VideoPlayerMobile = styled.iframe`
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 50%;
  height: 120vh;
  transform: translate(-50%, -50%);
`;

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 90vh;
  z-index: ${zIndex.backgrounds};

  ${desktopBreakpoint} {
    height: 115vh;
  }
`;

const HeroFooter = styled.div`
  position: absolute;
  bottom: ${mobileVW(40)};
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${mobileContentSidePadding};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    bottom: ${desktopVW(70)};
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${desktopVW(60)};
  }
`;

const HeroCatchphrase = styled.h2`
  color: ${({ detailsColor }) => (detailsColor ? `${colors.white}` : `${colors.black}`)};
  font-family: 'messinaMono';
  font-size: ${mobileVW(31)};
  padding-bottom: ${mobileVW(30)};
  font-weight: 400;
  letter-spacing: -0.05em;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(27)};
    max-width: ${desktopVW(300)};
    padding-bottom: 0;
  }
`;
